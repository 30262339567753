import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Config from 'commons/config'
import BaseComponent from 'components/parts/BaseComponent'
import FemaleTitle from 'images/talk_assist_guidance/female_title.png'
import Kagura from 'images/talk_assist_guidance/kagura.png'
import KaguraIcon from 'images/talk_assist_guidance/kagura_icon.png'
import MaleTitle from 'images/talk_assist_guidance/male_title.png'
import Sakaki from 'images/talk_assist_guidance/sakaki.png'
import SakakiIcon from 'images/talk_assist_guidance/sakaki_icon.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 321,
    borderRadius: 22,
    margin: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
  },
  serif: {
    fontSize: 12,
    fontWeight: 600,
    color: '#423BC7',
    lineHeight: 1,
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
  },
  kagura: {
    position: 'absolute',
    bottom: -30,
    left: 2,
  },
  sakaki: {
    position: 'absolute',
    bottom: -30,
    right: 5,
  },
  kaguraMale: {
    position: 'absolute',
    top: 84,
    right: 19,
  },
  sakakiMale: {
    position: 'absolute',
    top: 108,
    left: 14,
  },
  kaguraFemale: {
    position: 'absolute',
    top: 122,
    right: 14,
  },
  sakakiFemale: {
    position: 'absolute',
    top: 144,
    left: 12,
  },
  button: {
    width: 194,
    height: 40,
    color: '#ffffff',
    background: '#616AFA',
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      color: '#ffffff',
      background: '#616AFA',
    },
  },
  naviTo: {
    color: '#555555',
    fontSize: 10,
    lineHeight: 1,
    whiteSpace: 'pre',
    textAlign: 'left',
    marginLeft: theme.spacing(8),
    '-webkit-user-select': 'none',
  },
}))

export default function TalkAssistGuidance (props) {
  const classes = useStyles()
  const { user, friend } = props
  if (!user) { return null }
  if (!friend) { return null }

  const createTitle = () => {
    if (user.sex === 'male') {
      return <img src={MaleTitle} width={200} />
    }
    if (user.sex === 'female') {
      return <img src={FemaleTitle} width={304} />
    }
    return null
  }

  const serif = user.sex === 'male' ? '女性側のムービーも見てね' : '聞きにくいことも代わりに聞くよ'

  const createKagura = () => {
    if (friend.type === 'ai') {
      return <img className={classes.kagura} src={Kagura} width={55} />
    } else {
      const classname = user.sex === 'male' ? classes.kaguraMale : classes.kaguraFemale
      return <img className={classname} src={KaguraIcon} width={38} />
    }
  }

  const createSakaki = () => {
    if (friend.type === 'ai') {
      return <img className={classes.sakaki} src={Sakaki} width={56} />
    } else {
      const classname = user.sex === 'male' ? classes.sakakiMale : classes.sakakiFemale
      return <img className={classname} src={SakakiIcon} width={46} />
    }
  }

  const openServiceSite = async () => {
    try {
      await Api.addActionLog('click_talk_assist_guidance')
    } catch (error) {
      BaseComponent.handleApiError(props, error)
    } finally {
      const url = Config.serviceSiteUrl + '#talk-assist-movie'
      window.open(url)
    }
  }

  return (
    <>
      <Grid
        className={classes.root}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          background: friend.type === 'ai' ? '#ffffff' : '#EFF0FF',
          border: friend.type === 'ai' ? 'solid 2px #5463F6' : 'dotted 2px #5463F6',
        }}
      >
        {createTitle()}
        <Typography className={classes.serif}>\ {serif} /</Typography>
        {createKagura()}
        {createSakaki()}
        <Button className={classes.button} variant="contained" onClick={openServiceSite}>
          アシスト例を見る
        </Button>
      </Grid>
      <Typography className={classes.naviTo}>
        {friend.type === 'ai' ? '' : '自分だけに表示'}
      </Typography>
    </>
  )
}

TalkAssistGuidance.propTypes = {
  user: PropTypes.object,
  friend: PropTypes.object,
}
