const host = window.location.host

const service = 'goen'
// app: アプリ用デプロイ
// entry: 会員登録用デプロイ
// members: メンバーズサイト用デプロイ
// partner: パートナーサイト用デプロイ
const deployMode = host.match(/partner(-dev)?\.aill\.ai/) ? 'partner' : host.match(/members(-dev)?\.aill\.ai/) ? 'members' : host.match(/entry(-dev)?\.aill\.ai/) ? 'entry' : 'app'

// ローカル起動(yarn start)で会員登録の動作確認する場合はこちら
// const deployMode = 'entry'

// ローカル起動(yarn start)でメンバーズページの動作確認する場合はこちら
// const deployMode = 'members'

// ローカル起動(yarn start)でパートナーページの動作確認する場合はこちら
// const deployMode = 'partner'

const stagingEnv  = host.match(/[a-z]+-dev(-\d+)?\.(aill-navi\.jp|aill\.ai)/)
const stagingEnv2 = host.match(/[a-z]+-dev-2\.(aill-navi\.jp|aill\.ai)/)
const localApi = false
const localApiFromAndroidEmulator = false

let apiBaseUrl = ''
let apiPathPrefix = ''
let wssBaseUrl = ''
let appBaseUrl = ''
let serviceSiteUrl = ''
let entrySiteUrl = ''
let membersSiteUrl = ''
let memberCorporationsUrl = ''
let sentryDSN = ''

if (process.env.NODE_ENV === 'production' && !stagingEnv) {
  apiBaseUrl = 'https://api.aill-navi.jp'
  apiPathPrefix = '/v2'
  wssBaseUrl = 'wss://chat-v2.aill-navi.jp/cable?auth_token='
  appBaseUrl = 'https://app-v2.aill-navi.jp'
  serviceSiteUrl = 'https://aill.ai/'
  entrySiteUrl = 'https://entry.aill.ai/'
  membersSiteUrl = 'https://members.aill.ai/'
  memberCorporationsUrl = 'https://aill.ai/member-corporations.html'
  sentryDSN = 'https://a66bff767c7d4966accd9dbf31e1193d@o1278477.ingest.sentry.io/6478171'
} else if (!localApi) {
  apiBaseUrl = 'https://api.aill-navi.jp'
  apiPathPrefix = stagingEnv2 ? '/dev-2' : '/dev'
  wssBaseUrl = `wss://chat-dev${stagingEnv2 ? '-2' : ''}.aill-navi.jp/cable?auth_token=`
  appBaseUrl = `https://app-dev${stagingEnv2 ? '-2' : ''}.aill-navi.jp`
  serviceSiteUrl = 'https://dev.aill.ai/'
  entrySiteUrl = 'https://entry-dev.aill.ai/'
  membersSiteUrl = 'https://members-dev.aill.ai/'
  memberCorporationsUrl = 'https://dev.aill.ai/member-corporations.html'
  sentryDSN = 'https://c5b687fadb3543cc9c8add6892f7d273@o1278477.ingest.sentry.io/6482330'
} else {
  const localHost = localApiFromAndroidEmulator ? '10.0.2.2' : 'localhost'
  apiBaseUrl = `http://${localHost}:3000`
  apiPathPrefix = ''
  wssBaseUrl = 'ws://localhost:3000/cable?auth_token='
  appBaseUrl = `http://${localHost}:8080`
  serviceSiteUrl = 'https://dev.aill.ai/'
  entrySiteUrl = 'https://entry-dev.aill.ai/'
  membersSiteUrl = 'https://members-dev.aill.ai/'
  memberCorporationsUrl = 'https://dev.aill.ai/member-corporations.html'
  sentryDSN = 'https://c5b687fadb3543cc9c8add6892f7d273@o1278477.ingest.sentry.io/6482330'
}

// Universal Analytics (UA/GA3)
const uaTrackingId = (mode => {
  if (stagingEnv || stagingEnv2) { return 'UA-163597725-9' }
  switch (mode) {
    case 'app':
      return 'UA-163597725-7'
    case 'entry':
      return 'UA-163597725-5'
    case 'members':
      return 'UA-163597725-5'
    case 'partner':
      return 'UA-163597725-5'
    default:
      return ''
  }
})(deployMode)

// Google Analytics (GA4)
const gaTrackingId = (mode => {
  if (stagingEnv || stagingEnv2) { return 'G-B3FJ7382J3' }
  switch (mode) {
    case 'app':
      return 'G-JCRZVWLCMJ'
    case 'entry':
      return 'G-2Y6WHB6Y6K'
    case 'members':
      return 'G-2Y6WHB6Y6K'
    case 'partner':
      return 'G-2Y6WHB6Y6K'
    default:
      return ''
  }
})(deployMode)

export default {
  stagingEnv,
  service,
  deployMode,
  apiBaseUrl,
  apiPathPrefix,
  wssBaseUrl,
  appBaseUrl,
  serviceSiteUrl,
  entrySiteUrl,
  membersSiteUrl,
  memberCorporationsUrl,
  sentryDSN,
  uaTrackingId,
  gaTrackingId,
}
