import React, { useState, useEffect } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import Const from 'commons/constant'
import Currency from 'commons/currency'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import PhotoFilter from 'components/parts/PhotoFilter'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#1A1667',
  },
  subtitle: {
    width: '100%',
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    background: '#F4F7FE',
    lineHeight: 1,
    textAlign: 'left',
    borderRadius: 4,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  text: {
    width: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: '#1A1667',
    textAlign: 'left',
    letterSpacing: 0.7,
  },
  attention: {
    color: '#423BC7',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    '& svg': {
      width: 24,
    },
  },
  label: {
    color: '#616AFA',
  },
  radio: {
    '& svg': {
      width: 24,
    },
  },
  faceAvatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  nickName: {
    width: 100,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actions: {
    paddingTop: theme.spacing(1),
  },
  containedButton: {
    background: '#616AFA !important',
  },
  textButton: {
    color: '#616AFA !important',
  },
}))

function PlanConfirmDialog (props) {
  const { open, onClose, nextBilling, onConfirmOK, subscription, matchingState } = props
  if (!nextBilling || !subscription) { return null }

  const [friends, setFriends] = useState([])
  const [loadingFriends, setLoadingFriends] = useState(false)
  const [keepFriendUserId, setKeepFriendUserId] = useState('')
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!open) { return }
    if (nextBilling.plan_id !== Const.planTypes.FREE) { return }
    if (!currentExpired) { return }

    async function loadFriends () {
      setLoadingFriends(true)
      try {
        const allFriends = await Api.getFriends()
        const friendTypes = ['friend', 'lover_applying', 'lover_receiving', 'lover', 'friend_pending']
        const _friends = allFriends.filter(f => friendTypes.includes(f.friend_type))
        setFriends(_friends)
      } catch (error) {
        BaseComponent.handleApiError(props, error)
      } finally {
        setLoadingFriends(false)
      }
    }
    setFriends([])
    setKeepFriendUserId('')
    loadFriends()
  }, [open])

  const currentPlanId = subscription.content
  const currentFree = (currentPlanId === Const.planTypes.FREE)
  const currentExpired = (() => {
    if (!subscription.expired_at) { return false }
    return new Date(subscription.expired_at) < new Date()
  })()
  const nextPlanId = nextBilling.plan_id
  const nextMonths = nextBilling.payment_months
  const billingDate = new Date(nextBilling.billing_date)

  const planTitleMap = {
    [Const.planTypes.FREE]: 'フリープラン',
    [Const.planTypes.SPECIAL_FREE]: '特別フリープラン',
    [Const.planTypes.STANDARD]: 'スタンダードプラン',
  }
  const planTitle = planTitleMap[nextPlanId]
  const classes = useStyles()

  const cancelDialog = () => {
    setChecked(false)
    onClose && onClose()
  }

  const contentsOfStandard = () => {
    const labelPrefix = (currentFree || currentExpired) ? '今回の' : '更新時の'
    const remainingTargetCount = matchingState?.good_remaining_target_count
    const attention = remainingTargetCount && (remainingTargetCount < (15 * nextMonths))
    return (
      <>
        {(nextBilling.campaign_titles && 0 < nextBilling.campaign_titles.length) && (
          <>
            <Typography className={classes.subtitle} variant="subtitle1">{labelPrefix}適用されるキャンペーン等</Typography>
            <Typography className={classes.text} variant="body1">{nextBilling.campaign_titles.join(', ')}</Typography>
          </>
        )}
        <Typography className={classes.subtitle} variant="subtitle1">ご請求額</Typography>
        <Typography className={classes.text} variant="body1" data-testid="plan-confirm-amount">{Currency.format(nextBilling.amount)}円</Typography>

        <Typography className={classes.subtitle} variant="subtitle1">プラン更新日</Typography>
        <Typography className={classes.text} variant="body1" data-testid="plan-confirm-term">{dateFormat(billingDate, 'yyyy年m月d日')}&nbsp;から&nbsp;{nextMonths}ヶ月毎</Typography>

        <Typography className={classes.subtitle} variant="subtitle1">注意事項</Typography>
        {(!currentFree && !currentExpired) && (
          <Typography className={classes.text} variant="body1">
            ＊自動更新時に、次月分のご利用料金をご登録中のクレジットカードから請求させていただきます。<br />
            {!!attention && <span className={classes.attention}>＊お住まい及びご年齢により、ご紹介できる異性の人数が少なくなる可能性があります。</span>}
          </Typography>
        )}
        {(!currentFree && currentExpired) && (
          <Typography className={classes.text} variant="body1">
            ＊現在のお友達も引き続きトークが可能ですので、ご安心ください。<br />
            ＊継続と同時に、ご登録のクレジットカードにて決済が行われます。<br />
            ＊有料プランの申込完了日から{nextMonths}ヶ月毎に、次月分のご利用料金をご登録中のクレジットカードから請求させていただきます。<br />
            ＊有料プランの有効期間内に無料プランへ変更もしくは退会された場合、料金の日割り計算による払い戻しはできませんので、ご了承ください。<br />
            {!!attention && <span className={classes.attention}>＊お住まい及びご年齢により、ご紹介できる異性の人数が少なくなる可能性があります。</span>}
          </Typography>
        )}
        {(currentFree) && (
          <Typography className={classes.text} variant="body1">
            ＊自動更新時に、次月分のご利用料金をご登録中のクレジットカードから請求させていただきます。<br />
            ＊同時にトークができる人数が10名となります。<br />
            ＊変更と同時に、ご登録のクレジットカードにて決済が行われます。<br />
            ＊有料プランの有効期間内に無料プランへ変更もしくは退会された場合、料金の日割り計算による払い戻しはできませんので、ご了承ください。<br />
            {!!attention && <span className={classes.attention}>＊お住まい及びご年齢により、ご紹介できる異性の人数が少なくなる可能性があります。</span>}
          </Typography>
        )}
      </>
    )
  }

  const createFriendInfo = (friend) => {
    return (
      <FormControlLabel
        key={friend.id}
        className={classes.friendInfo}
        value={friend.id}
        control={<Radio className={classes.radio} style={{ color: '#616AFA' }} />}
        label={(
          <Grid container wrap="nowrap" justifyContent="flex-start" alignItems="center">
            <PhotoFilter brightness={friend.photo_filter_brightness}>
              <Avatar src={friend.photo_icon} className={classes.faceAvatar} />
            </PhotoFilter>
            <span className={classes.nickName}>{friend.nick_name}</span>
          </Grid>
        )}
        classes={{ label: classes.label }}
      />
    )
  }

  const contentsOfFree = () => (
    <>
      <Typography className={classes.subtitle} variant="subtitle1">プラン更新日</Typography>
      <Typography className={classes.text} variant="body1">
        {dateFormat(subscription.expired_at, 'yyyy年m月d日')}にフリープランへ変更となります。<br />
        それまではスタンダードプランをご利用いただけます。
      </Typography>

      {2 <= friends.length && (
        <RadioGroup value={keepFriendUserId} onChange={e => setKeepFriendUserId(e.target.value)}>
          <Typography className={classes.subtitle} variant="subtitle1">トークを継続するお友達</Typography>
          {friends.map(friend => createFriendInfo(friend))}
        </RadioGroup>
      )}

      {(!currentFree && !currentExpired) && (
        <>
          <Typography className={classes.subtitle} variant="subtitle1">注意事項</Typography>
          <Typography className={classes.text} variant="body1">
            ＊2人以上とトーク中の場合、ご利用プラン更新時にトークを継続する1人をご選択いただけます。<br />
            ＊有料プランから無料プランに変更した時点で、次のご利用料金の引き落としは行われません。<br />
            {/* ＊有料プランの有効期間内に無料プランへ変更された場合、料金の日割り計算による払い戻しはできませんので、ご了承ください。 */}
          </Typography>
        </>
      )}
      {(!currentFree && currentExpired) && (
        <>
          <Typography className={classes.subtitle} variant="subtitle1">注意事項</Typography>
          <Typography className={classes.text} variant="body1">
            ＊トークが複数開通している場合、1名を選択してその他は友達解消となります。友達解消後は、トークを復元させることはできませんのでご注意ください。<br />
            ＊有料プランから無料プランに変更した時点で、次のご利用料金の引き落としは行われません。<br />
            {/* ＊有料プランの有効期間内に無料プランへ変更された場合、料金の日割り計算による払い戻しはできませんので、ご了承ください。 */}
          </Typography>
        </>
      )}
    </>
  )

  const contentsOfSpecialFree = () => (
    <>
      <Typography className={classes.subtitle} variant="subtitle1">プラン更新日</Typography>
      <Typography className={classes.text} variant="body1">
        {dateFormat(subscription.expired_at, 'yyyy年m月d日')}に特別フリープランへ変更となります。<br />
        それまではスタンダードプランをご利用いただけます。
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">注意事項</Typography>
      <Typography className={classes.text} variant="body1">
        ＊有料プランから無料プランに変更した時点で、次のご利用料金の引き落としは行われません。<br />
      </Typography>
    </>
  )

  const disabledOkButton = () => {
    // フリープランに変更時: 期限切れかつ複数人とトーク開通している場合は1人選択する必要がある
    if (nextPlanId === Const.planTypes.FREE && friends) {
      if (2 <= friends.length && !keepFriendUserId && currentExpired) {
        return true
      }
    }
    return loadingFriends || !checked
  }

  return (
    <DialogThemeProvider color="default">
      <Dialog open={open} data-testid="plan-confirm-dialog">
        <DialogTitle className={classes.title} disableTypography>プラン継続・変更のご確認</DialogTitle>
        <DialogContent>
          <Typography className={classes.subtitle} variant="subtitle1">ご利用されるプラン</Typography>
          <Typography className={classes.text} variant="body1" data-testid="plan-confirm-type">{planTitle}</Typography>
          {nextPlanId === Const.planTypes.STANDARD && contentsOfStandard()}
          {nextPlanId === Const.planTypes.FREE && contentsOfFree()}
          {nextPlanId === Const.planTypes.SPECIAL_FREE && contentsOfSpecialFree()}
          <FormControlLabel
            className={classes.form}
            control={
              <Checkbox
                className={classes.checkbox}
                checked={checked}
                onChange={() => setChecked(!checked)}
                style={{ color: '#616AFA' }}
              />
            }
            label="同意しました"
            classes={{ label: classes.label }}
          />
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button
            className={classes.containedButton}
            variant="contained"
            onClick={() => onConfirmOK && onConfirmOK(keepFriendUserId)}
            disabled={disabledOkButton()}
          >
            プランを継続・変更する
          </Button>
          <Button className={classes.textButton} variant="text" onClick={cancelDialog}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </DialogThemeProvider>
  )
}
PlanConfirmDialog.propTypes = {
  open: PropTypes.bool,
  subscription: PropTypes.object,
  nextBilling: PropTypes.object,
  organization: PropTypes.object,
  matchingState: PropTypes.object,
  onConfirmOK: PropTypes.func,
  onClose: PropTypes.func,
  user: PropTypes.object,
  friends: PropTypes.object,
}
export default PlanConfirmDialog