import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Currency from 'commons/currency'
import { formatJapaneseDate } from 'commons/utility'

const useStyles = makeStyles(theme => ({
  planItem: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: theme.spacing(1.25),
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1.75)}px ${theme.spacing(2)}px`,
    position: 'relative',
  },
  planInterval: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1A1667',
    lineHeight: `${theme.spacing(2)}px`,
    width: theme.spacing(7.5),
    background: '#F4F7FE',
    whiteSpace: 'pre',
    borderRadius: theme.spacing(0.625),
    padding: `${theme.spacing(1.75)}px ${theme.spacing(1.125)}px`,
  },
  campaignValueText: {
    fontSize: 14,
    color: '#707070',
    textAlign: 'center',
  },
  planValue: {
    fontSize: 14,
    color: '#707070',
    '& span': {
      fontSize: 24,
      fontWeight: 'bold',
    }
  },
  planTotalValue: {
    fontSize: 12,
    color: '#707070',
    textAlign: 'center',
  },
  entryButton: {
    fontSize: 13,
    width: theme.spacing(25),
    height: theme.spacing(4.5),
  },
  minimumLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#FFFFFF',
    width: theme.spacing(12.125),
    height: theme.spacing(2.625),
    background: '#F55083',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    textAlign: 'center',
    lineHeight: '12px',
    borderRadius: theme.spacing(1.375),
    position: 'absolute',
    top: '-10px',
    left: '-2px',
  },
}))

export default function PlanItem (props) {
  const { fee, nextFee, disabledSelect, onClick, best } = props
  const classes = useStyles()

  if (!fee) { return null }

  const createInterval = () => (
    <div className={classes.planInterval}>
      {fee.payment_months}ヶ月<br />プラン
    </div>
  )

  const createPrice = () => (
    <div>
      <div className={classes.planValue}>
        月<span>&nbsp;&nbsp;{Currency.format(fee.price_per_month)}</span>円（税込）
      </div>
      {fee.payment_months !== 1 && (
        <div className={classes.planTotalValue}>
          一括&nbsp;&nbsp;{Currency.format(fee.price)}円（税込）
        </div>
      )}
      {nextFee && (
        <div className={classes.planTotalValue}>
          ＊{formatJapaneseDate(nextFee.start_date)}から{Currency.format(nextFee.price)}円（税込）
        </div>
      )}
    </div>
  )

  return (
    <Grid
      className={classes.planItem}
      container
      justifyContent="space-between"
      alignItems="center"
      data-testid={`plan-item-${fee.plan_type}-${fee.payment_months}`}
    >
      {best && <div className={classes.minimumLabel}>1番おトク！</div>}
      {createInterval()}
      {createPrice()}
      <Box sx={{ mx: { xs: 'auto', md: 0 }, mt: { xs: 1.75, md: 0 } }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.entryButton}
          disabled={disabledSelect}
          onClick={() => onClick(fee.plan_type, fee.payment_months)}
          data-testid={`plan-item-button-${fee.plan_type}-${fee.payment_months}`}
        >
          {disabledSelect ? 'ご利用中のプラン' : 'このプランに変更する'}
        </Button>
      </Box>
    </Grid>
  )
}

PlanItem.propTypes = {
  fee: PropTypes.object.isRequired,
  nextFee: PropTypes.object,
  disabledSelect: PropTypes.bool,
  onClick: PropTypes.func,
  best: PropTypes.bool,
}
