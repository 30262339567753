import Config from 'commons/config'

const DB_NAME = 'aill_goen'
const DB_VERSION = 1
const TABLE_NAME_CONFIGS = 'configs'

const idb = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.moz_indexedDB

const notUseIDB = (() => {
  if (Config.deployMode !== 'app') { return true }
  if (process.env.NODE_ENV === 'test') { return true }
  return false
})()

const openIDB = () => {
  const request = idb.open(DB_NAME, DB_VERSION)
  request.onupgradeneeded = (event) => {
    const db = event.target.result
    console.log(`upgrade database "${DB_NAME}".`)
    switch (db.version) {
      case 0:
      case 1: {
        db.createObjectStore(TABLE_NAME_CONFIGS, { keyPath: 'key' })
        break
      }
      default:
        break
    }
  }
  return request
}

const initIndexedDB = () => {
  if (notUseIDB) { return }
  if (!idb) { return }

  const request = openIDB()

  request.onerror = (event) => {
    console.log(`failed open database "${DB_NAME}".`, event.target.error)
  }

  request.onsuccess = (event) => {
    const db = event.target.result
    console.log(`init IndexedDB "${DB_NAME}". database version is ${db.version}.`)
  }
}

const get = (store, key) => {
  if (notUseIDB) { return null }
  if (!idb) { return null }

  return new Promise((resolve, reject) => {
    const request = openIDB()
    request.onsuccess = (event) => {
      const db = event.target.result
      try {
        const transaction = db.transaction(store, 'readonly')
        const objectStore = transaction.objectStore(store)
        objectStore.get(key).onsuccess = (event) => {
          resolve(event.target.result?.value)
        }
        db.close()
      } catch (error) {
        reject(error)
      }
    }
    request.onerror = (event) => {
      reject(event.target.error)
    }
  })
}

const put = (store, key, value) => {
  if (notUseIDB) { return }
  if (!idb) { return }

  return new Promise((resolve, reject) => {
    const request = openIDB()
    request.onsuccess = (event) => {
      const db = event.target.result
      try {
        const transaction = db.transaction(store, 'readwrite')
        const objectStore = transaction.objectStore(store)
        objectStore.put({ key: key, value: value }).onsuccess = () => {
          resolve()
        }
        db.close()
      } catch (error) {
        reject(error)
      }
    }
    request.onerror = (event) => {
      reject(event.target.error)
    }
  })
}

const clear = (store, key) => {
  if (notUseIDB) { return }
  if (!idb) { return }

  return new Promise((resolve, reject) => {
    const request = openIDB()
    request.onsuccess = (event) => {
      const db = event.target.result
      try {
        const transaction = db.transaction(store, 'readwrite')
        const objectStore = transaction.objectStore(store)
        objectStore.delete(key).onsuccess = () => {
          resolve()
        }
        db.close()
      } catch (error) {
        reject(error)
      }
    }
    request.onerror = (event) => {
      reject(event.target.error)
    }
  })
}

const getConfig = async (key) => {
  return await get(TABLE_NAME_CONFIGS, key)
}

const setConfig = async (key, value) => {
  await put(TABLE_NAME_CONFIGS, key, value)
}

const clearConfig = async (key) => {
  await clear(TABLE_NAME_CONFIGS, key)
}

export default {
  initIndexedDB,
  setConfig,
  getConfig,
  clearConfig,
}