import * as Sentry from '@sentry/react'
import IndexedDB from 'commons/indexed_db'
import Storage, * as LocalStorage from 'commons/local_storage'
export default Storage
export * from 'commons/local_storage'

// ======================================================================
// トークン関連
// ======================================================================
const KEY_AILL_TOKEN = 'aill_token'

export async function getToken () {
  try {
    const key = KEY_AILL_TOKEN
    const token = LocalStorage.get(key) || await IndexedDB.getConfig(key)
    return token
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

export async function setToken (token) {
  try {
    const key = KEY_AILL_TOKEN
    LocalStorage.set(key, token)
    await IndexedDB.setConfig(key, token)
    saveTokenToNative(token)
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function setTokenToLocalStorage (token) {
  LocalStorage.set(KEY_AILL_TOKEN, token)
}

export async function clearToken () {
  try {
    const key = KEY_AILL_TOKEN
    LocalStorage.clear(key)
    await IndexedDB.clearConfig(key)
    clearTokenInNative()
  } catch (error) {
    Sentry.captureException(error)
  }
}

export async function getTokenFromNative () {
  try {
    // iOS
    if (window.webkit?.messageHandlers?.readAuthToken) {
      return await createPromiseGetTokenFromIOs(
        window.webkit.messageHandlers.readAuthToken
      )
    }
    // Android
    if (window.android?.readAuthToken) {
      return window.android.readAuthToken()
    }
  } catch (error) {
    console.log(error)
    return null
  }
  return null
}

function createPromiseGetTokenFromIOs (handler) {
  return new Promise((resolve, reject) => {
    const randNum = Math.floor(Math.random() * 100000000)
    const callbackFuncName = 'readAuthTokenCallback' + randNum
    if (!window[callbackFuncName]) {
      window[callbackFuncName] = (token) => {
        window[callbackFuncName] = undefined
        if (token) {
          resolve(token)
        } else {
          reject(new Error('Failed to read token from native layer.'))
        }
      }
    }
    handler.postMessage('window.' + callbackFuncName)
  })
}

export function saveTokenToNative (token) {
  // iOS
  if (window.webkit?.messageHandlers?.saveAuthToken) {
    window.webkit.messageHandlers.saveAuthToken.postMessage(token)
  }
  // Android
  if (window.android?.saveAuthToken) {
    window.android.saveAuthToken(token)
  }
}

export function clearTokenInNative () {
  // iOS
  if (window.webkit?.messageHandlers?.clearAuthToken) {
    window.webkit.messageHandlers.clearAuthToken.postMessage('')
  }
  // Android
  if (window.android?.clearAuthToken) {
    window.android.clearAuthToken()
  }
}
