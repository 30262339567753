import React from 'react'
import { isNullOrUndefined } from 'util'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Api from 'commons/api'
import * as storage from 'commons/storage'
import DialogThemeProvider from 'commons/theme/DialogThemeProvider'
import BaseComponent from 'components/parts/BaseComponent'
import BusyDateCard from 'components/parts/BusyDateCard'
import ConfirmDialog from 'components/parts/ConfirmDialog'
import ImportantConfirmDialog from 'components/parts/ImportantConfirmDialog'
import LoverEntryDialog from 'components/parts/LoverEntryDialog'
import NaviButton from 'components/parts/NaviButton'
import RatingSelect from 'components/parts/RatingSelect'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  answeredLabel: {
    fontSize: 12,
    lineHeight: 1,
    opacity: 0.5,
    marginTop: theme.spacing(2),
  },
  wantToKnowContainer: {
    minWidth: theme.spacing(27.5),
    maxWidth: `calc(100% - ${theme.spacing(11.5)}px)`,
    marginBottom: theme.spacing(2),
  },
  wantToKnowLabel: {
    fontSize: 16,
  },
  wantToKnowSelect: {
    fontSize: 14,
    '& div': {
      maxWidth: 220 - 24,
      '&:focus': {
        background: '#ffffff',
      },
    },
  },
})

const wantToKnowValues = [
  'merit',
  'demerit',
  'how_to_spend_holiday',
  'hobby',
  'life_cycle',
  'family_structure',
  'talk_compat',
  'job',
  'progress_possibility',
  'number_of_rivals',
  'next_date',
  'none'
]

const wantToKnowValues2 = [
  'career_plan',
  'family_structure',
  'communication_with_family',
  'friendship',
  'value_for_money',
  'demerit',
  'just_quit',
  'how_long_want_to_stay',
  'life_plan',
  'marriage_age',
  'progress_possibility',
  'number_of_rivals',
  'none'
]

// トーク上の好感度アンケート
class NaviQuestion extends BaseComponent {
  constructor (props) {
    super(props)

    this.createButtonList = this.createButtonList.bind(this)
    this.createWantToKnowSelects = this.createWantToKnowSelects.bind(this)
    this.handleSelectFavRating = this.handleSelectFavRating.bind(this)
    this.handleChangeWantToKnow = this.handleChangeWantToKnow.bind(this)
    this.handleSendWantToKnow = this.handleSendWantToKnow.bind(this)
    this.handleUpdateBusyDate = this.handleUpdateBusyDate.bind(this)
    this.handleConfirmOK = this.handleConfirmOK.bind(this)
    this.closeEntryConfirmDialog = this.closeEntryConfirmDialog.bind(this)
    this.closeLoverEntryDialog = this.closeLoverEntryDialog.bind(this)
    this.handleSentLoverEntry = this.handleSentLoverEntry.bind(this)
    this.closeLoverConfirmDialog = this.closeLoverConfirmDialog.bind(this)
    this.handleSentLover = this.handleSentLover.bind(this)

    let wantToKnowVals = NaviQuestion.parseWantToKnowValues(props.message)

    // 回答確認ダイアログでOKする前の値を保持する用
    this.selectedAnswer = null
    this._isMounted = false

    this.state = {
      answer: props.message.fav_question_answer,
      wantToKnow1: (0 < wantToKnowVals.length ? wantToKnowVals[0] : ''),
      wantToKnow2: (1 < wantToKnowVals.length ? wantToKnowVals[1] : ''),
      wantToKnow3: (2 < wantToKnowVals.length ? wantToKnowVals[2] : ''),
      openConfirm: false,
      answerToConfirm: '',
      openEntryConfirmDialog: false,
      openLoverEntryDialog: false,
      openLoverConfirmDialog: false,
      okDisabled: false,
    }
  }

  componentWillReceiveProps (nextProps) {
    if (JSON.stringify(this.props.message) !== JSON.stringify(nextProps.message)) {
      let wantToKnowVals = NaviQuestion.parseWantToKnowValues(nextProps.message)
      this.setStateIfMounted({
        answer: nextProps.message.fav_question_answer,
        wantToKnow1: (0 < wantToKnowVals.length ? wantToKnowVals[0] : ''),
        wantToKnow2: (1 < wantToKnowVals.length ? wantToKnowVals[1] : ''),
        wantToKnow3: (2 < wantToKnowVals.length ? wantToKnowVals[2] : ''),
      })
    }
  }

  static parseWantToKnowValues (msg) {
    if (msg.fav_question_type !== 'want_to_knows' && msg.fav_question_type !== 'want_to_knows_2') { return [] }
    if (!msg.fav_question_answer) { return [] }
    try {
      return JSON.parse(msg.fav_question_answer)
    } catch(e) {
      console.log(e)
    }
  }

  createButtonList (items) {
    return (
      <Grid container direction="column">
        {items.map(item => (
          <NaviButton
            key={item.value}
            naviType={this.props.naviType}
            selected={this.isAnseredItem(item)}
            onClick={() => this.handleClickButton(item)}
            disabled={this.getFriend()?.type === 'closed'}
          >
            {item.label}
          </NaviButton>
        ))}
      </Grid>
    )
  }

  isAnseredItem (item) {
    if (!item.value) { return false }
    let answer = this.state.answer ? this.state.answer : this.props.message.fav_question_answer
    if (!answer) { return false }

    let val = item.value
    if (val.toString() === answer.toString()) { return true }
    if (parseInt(val, 10) === parseInt(answer, 10)) { return true }
    return false
  }

  // 好感度
  createFavRating () {
    const answer = this.state.answer || this.props.message.fav_question_answer
    const val = answer ? parseInt(answer, 10) : -1
    return (
      <RatingSelect
        selectedRating={val}
        onSelect={this.handleSelectFavRating}
        messageId={this.props.message.id}
        answered={!!answer}
        closed={this.getFriend()?.type === 'closed'}
      />
    )
  }

  handleSelectFavRating (rating) {
    let answer = this.state.answer ? this.state.answer : this.props.message.fav_question_answer
    if (!isNullOrUndefined(answer)) { return }

    this.selectedAnswer = rating
    this.setState({
      answerToConfirm: `5段階中${rating}`,
      openConfirm: true,
    })
  }

  // 異性の気になるところ
  createWantToKnowSelects (index) {
    const { classes, master } = this.props
    if (!master?.want_to_know) { return null }
    const answer = this.state.answer || this.props.message.fav_question_answer
    const values = index === 1 ? wantToKnowValues : wantToKnowValues2
    // 3項目選択済み
    const selectedAll = this.state.wantToKnow1 && this.state.wantToKnow2 && this.state.wantToKnow3
    // 回答済み or closedの場合無効化
    const disabled = !!answer || this.getFriend()?.type === 'closed'

    return (
      <Grid container direction="column">
        {['1', '2', '3'].map(num => (
          <FormControl key={`want-to-know${num}`} className={classes.wantToKnowContainer}>
            <InputLabel htmlFor={`want-to-know${num}`} className={classes.wantToKnowLabel}>
              もっとココが知りたいな{num}
            </InputLabel>
            <Select
              className={classes.wantToKnowSelect}
              value={this.state[`wantToKnow${num}`]}
              onChange={this.handleChangeWantToKnow}
              inputProps={{
                name: `wantToKnow${num}`,
                id: `want-to-know${num}`
              }}
              disabled={disabled}
            >
              {values.map(val => (
                <MenuItem value={val} key={val}>
                  {master.want_to_know[val].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
        {!answer && (
          <NaviButton
            naviType={this.props.naviType}
            disabled={disabled || !selectedAll}
            onClick={this.handleSendWantToKnow}
          >
            回答
          </NaviButton>
        )}
      </Grid>
    )
  }

  handleChangeWantToKnow (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  createBusyDateCard () {
    let params = {}
    if (this.props.message.fav_question_answer) {
      try {
        params = JSON.parse(this.props.message.fav_question_answer)
      } catch (e) {
        console.log(e)
      }
    }
    return (
      <BusyDateCard
        params={params}
        onUpdate={this.handleUpdateBusyDate}
      />
    )
  }

  handleClickButton (item) {
    if (this.props.demo) {
      if (this.state.answer) { return }
      this.setState({ answer: item.value })
      this.props.onAnswerDemoQuestion(item.value)
      return
    }
    let answer = this.state.answer ? this.state.answer : this.props.message.fav_question_answer
    if (!isNullOrUndefined(answer)) { return }
    if (item.value === answer) { return }
    if (!storage.getFriend()) { return }

    this.selectedAnswer = item.value
    if (this.props.message.fav_question_type === 'apply_lover' && item.value === 'yes') {
      this.setState({ openEntryConfirmDialog: true })
    } else if (this.props.message.fav_question_type === 'is_lover' && item.value === 'yes') {
      this.setState({ openLoverConfirmDialog: true })
    } else {
      this.setState({
        answerToConfirm: item.label,
        openConfirm: true,
      })
    }
  }

  handleSendWantToKnow () {
    const { master } = this.props
    if (!this.state.wantToKnow1) { return }
    if (!this.state.wantToKnow2) { return }
    if (!this.state.wantToKnow3) { return }

    let vals = ['1', '2', '3'].map(num => this.state[`wantToKnow${num}`])

    this.selectedAnswer = vals
    this.setState({
      answerToConfirm: vals.map(val => master.want_to_know[val].title).join('・'),
      openConfirm: true,
    })
  }

  getFriend () {
    return this.props.friend ? this.props.friend : storage.getFriend()
  }

  handleUpdateBusyDate (params) {
    if (this.props.onAnswerQuestion) {
      this.props.onAnswerQuestion(params)
    }
  }

  // 回答確認ダイアログでキャンセル押下
  handleCancel = () => {
    this.selectedAnswer = null
    this.setState({
      openConfirm: false,
      openEntryConfirmDialog: false,
      openLoverEntryDialog: false,
      openLoverConfirmDialog: false,
    })
  }

  // 回答確認ダイアログでOK押下
  async handleConfirmOK () {
    if (this.state.okDisabled) { return }

    await this.sendAnswer()
    this.setStateIfMounted({ openConfirm: false })
  }

  closeEntryConfirmDialog () {
    this.setState({
      openEntryConfirmDialog: false,
      openLoverEntryDialog: true,
    })
  }

  closeLoverEntryDialog () {
    this.setState({ openLoverEntryDialog: false })
  }

  async handleSentLoverEntry (inputMessage) {
    await this.sendAnswer(inputMessage)
    this.setStateIfMounted({ openLoverEntryDialog: false })
  }

  closeLoverConfirmDialog () {
    this.setState({ openLoverConfirmDialog: false })
  }

  async handleSentLover () {
    await this.sendAnswer()
    this.setStateIfMounted({ openLoverConfirmDialog: false })
    this.intervalId = window.setInterval(() => this.checkGraduated(), 1000)
  }

  async sendAnswer (inputMessage = null) {
    let friendId = this.getFriend().id
    let params = {}
    let fav_question_type = this.props.message.fav_question_type
    params[fav_question_type] = this.selectedAnswer
    this.setState({ okDisabled: true })
    try {
      this.setStateIfMounted({ answer: this.selectedAnswer })
      await this.loadFriendDetailWithId(friendId)
      if (this.props.onAnswerQuestion) {
        this.props.onAnswerQuestion(this.selectedAnswer, inputMessage)
      }
    } catch (error) {
      this.handleApiError(error)
      this.handleCancel()
    } finally {
      this.setState({ okDisabled: false })
    }
  }

  async checkGraduated () {
    let room = await Api.getChatRoom(this.getFriend().chat_room_id)

    if (room.relationship === 'lover') {
      window.clearInterval(this.intervalId)
      await this.loadFriends()
      this.props.setScreen('Home')
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
    window.clearInterval(this.intervalId)
  }

  render () {
    const { classes, ...other } = this.props
    const answer_choices = this.props.message.question_answer_choices
    const fav_question_type = this.props.message.fav_question_type

    let entryConfirmTitle = []
    entryConfirmTitle.push(<span key="msg1">{'お付き合いリクエスト'}</span>)
    entryConfirmTitle.push(<span key="msg2"><br /></span>)
    entryConfirmTitle.push(<span key="msg3">{'注意事項'}</span>)

    let entryConfirmMessage = []
    entryConfirmMessage.push(<span key="msg1">{'1.  お付き合いが成立した場合、他のトーク中の異性は必然的に友達解消となります。'}</span>)
    entryConfirmMessage.push(<span key="msg2"><br /><br /></span>)
    entryConfirmMessage.push(<span key="msg3">{'2.  10日以内に相手から返事がない場合は友達解消となります。'}</span>)

    return (
      <div className={classes.root}>
        {
          (fav_question_type === 'rating' || fav_question_type === 'rating_oneside') ? (
            this.createFavRating()
          ) : answer_choices ? (
            this.createButtonList(answer_choices)
          ) : (fav_question_type === 'want_to_knows') ? (
            this.createWantToKnowSelects(1)
          ) : (fav_question_type === 'want_to_knows_2') ? (
            this.createWantToKnowSelects(2)
          ) : (fav_question_type === 'busy_date') ? (
            this.createBusyDateCard()
          ) : null
        }
        {
          this.props.message.fav_question_answer ? (
            <Typography className={classes.answeredLabel}>回答済み</Typography>
          ) : null
        }
        <DialogThemeProvider color="default">
          <Dialog open={this.state.openConfirm}>
            <DialogTitle disableTypography>アンケート内容の確認</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                「{this.state.answerToConfirm}」でよろしいですか？
              </Typography>
            </DialogContent>
            <DialogActions disableSpacing>
              <Button variant="contained" disabled={this.state.okDisabled} onClick={this.handleConfirmOK}>
                回答
              </Button>
              <Button variant="text" onClick={this.handleCancel}>
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
        </DialogThemeProvider>
        <ImportantConfirmDialog
          open={this.state.openEntryConfirmDialog}
          onClose={this.closeEntryConfirmDialog}
          onOk={this.closeEntryConfirmDialog}
          onCancel={this.closeEntryConfirmDialog}
          attention="yes"
          title={entryConfirmTitle}
          message={entryConfirmMessage}
        />
        <ConfirmDialog
          open={this.state.openLoverConfirmDialog}
          onClose={this.closeLoverConfirmDialog}
          onOk={this.handleSentLover}
          onCancel={this.closeLoverConfirmDialog}
          question="yes"
          title="「お付き合い時」注意事項"
          message="■お付き合いが成立した場合、他のトーク中の異性は必然的に友達解消となります"
        />
        {
          this.props.message.fav_question_type === 'apply_lover' &&
          <LoverEntryDialog
            {...other}
            open={this.state.openLoverEntryDialog}
            onSent={this.handleSentLoverEntry}
            onCancel={this.closeLoverEntryDialog}
            friend={this.getFriend()}
            lover_type="apply"
          />
        }
      </div>
    )
  }
}

export default withStyles(styles)(NaviQuestion)
