import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import IndexDB from 'commons/indexed_db'
import Config from './commons/config'
import { setTokenToLocalStorage } from './commons/storage'
import App from './containers/app' //メインコンポーネント
import configureStore from './store/configureStore'

// IE, Android端末デフォルトブラウザ対応
import 'babel-polyfill'

// 本番ではログを出さないようにする
if (process.env.NODE_ENV === 'production' && !Config.stagingEnv) {
  console.log = () => {return}
  console.debug = () => {return}
  // console.error = () => {return}
  console.info = () => {return}
  console.warn = () => {return}
}

Sentry.init({
  dsn: Config.sentryDSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

IndexDB.initIndexedDB()

// GOEN-787 ネイティブ層から保存済みの認証トークンを渡して復元
const urlParams = new URLSearchParams(window.location.search)
const authToken = urlParams.get('auth_token')
if (authToken) {
  setTokenToLocalStorage(authToken)
}

const store = configureStore()
const rootEl = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl
)
