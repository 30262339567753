import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import Config from 'commons/config'
import { HEADER_HEIGHT } from 'commons/theme/MembersSiteTheme'
import logoImage from 'images/logo_aill_2021_bgclear.png'

const useStyles = makeStyles(theme => ({
  root: {
    height: HEADER_HEIGHT,
    backgroundColor: '#F4F7FE !important',
    boxShadow: 'none !important',
    borderBottom: '1px solid #707070',
  },
  toolbar: {
    height: '100%',
    padding: '99 !important'
  },
  logoContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  logo: {
    height: 43,
  },
  iconButton: {
    color: '#423BC7'
  },
  drawer: {
    zIndex: '99 !important',
  },
  paper: {
    top: HEADER_HEIGHT
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 600,
    color: '#423BC7',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textDecoration: 'none',
    cursor: 'pointer',
  },
  listItem: {
    fontSize: 16,
    fontWeight: 600,
    color: '#1A1667',
    justifyContent: 'center',
  },
}))

export default function MembersHeader (props) {
  const { scroll, logout, organization, billings } = props
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  const createPCItem = (text, onClick) => (
    <a className={classes.menuItem} onClick={onClick}>{text}</a>
  )

  const createSPItem = (text, onClick) => (
    <ListItem className={classes.listItem} button onClick={onClick}>
      {text}
    </ListItem>
  )

  const selectDrawerMenuItem = async (refName) => {
    await setOpenDrawer(false)
    scroll(refName)
  }

  const createPCItems = () => {
    if (!organization) { return null }
    if (!billings) { return null }

    const orgPaid = organization.full_paid_enabled
    return (
      <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
        {createPCItem('ご利用中のプラン', () => scroll('currentPlanRef'))}
        {createPCItem('プラン変更', () => scroll('selectPlanRef'))}
        {createPCItem('お支払い方法', () => scroll('paymentRef'))}
        {createPCItem('お支払い履歴', () => scroll('paymentHistoryRef'))}
        {orgPaid ? null : createPCItem('お友達紹介', () => scroll('invitationCampaignRef'))}
        {orgPaid ? null : createPCItem('クーポンコード', () => scroll('couponRef'))}
        {createPCItem('ログアウト', () => logout())}
      </Box>
    )
  }

  const createSPItems = () => {
    if (!organization) { return null }
    if (!billings) { return null }

    const orgPaid = organization.full_paid_enabled
    return (
      <List>
        {createSPItem('ご利用中のプラン', () => selectDrawerMenuItem('currentPlanRef'))}
        {createSPItem('プラン変更', () => selectDrawerMenuItem('selectPlanRef'))}
        {createSPItem('お支払い方法', () => selectDrawerMenuItem('paymentRef'))}
        {createSPItem('お支払い履歴', () => selectDrawerMenuItem('paymentHistoryRef'))}
        {orgPaid ? null : createSPItem('お友達紹介', () => selectDrawerMenuItem('invitationCampaignRef'))}
        {orgPaid ? null : createSPItem('クーポンコード', () => selectDrawerMenuItem('couponRef'))}
        {createSPItem('ログアウト', () => logout())}
      </List>
    )
  }

  const createHeader = () => (
    <AppBar className={classes.root} position="fixed">
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <a href={Config.membersSiteUrl}>
            <img src={logoImage} className={classes.logo} alt="" />
          </a>
        </div>
        <Box sx={{ flexGrow: 1 }} />
        {createPCItems()}
        <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
          <IconButton className={classes.iconButton} color='primary' onClick={() => setOpenDrawer(!openDrawer)}>
            {openDrawer ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Drawer
            className={classes.drawer}
            PaperProps={{ className: classes.paper }}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            anchor="top"
          >
            {createSPItems()}
          </Drawer>
        </Box>
      </Toolbar>
    </AppBar>
  )

  return (
    <>
      {React.cloneElement(
        createHeader(), { elevation: 1 }
      )}
      <Box sx={{ height: HEADER_HEIGHT }} />
    </>
  )
}

MembersHeader.propTypes = {
  scroll: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  organization: PropTypes.object,
  billings: PropTypes.array,
}
